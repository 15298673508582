import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="September 2020"
      subnav="release-notes">
      <div id="September2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          We may be coming into sweater weather, but a few of our icons are
          rockin' their new summer bod. We released a new video player
          component, but the documentation is little different than you'd
          expect—you're just gonna havta{' '}
          <a className="uni-link uni-link--article" href="/rn-2020-09.gif">
            deal with it
          </a>
          .
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.5.3"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.5.3"
            />

            <div className={styles.changesList}>
              <Added>
                A{' '}
                <Link href="/components/video-player/design">video player</Link>{' '}
                component to harness the power of all video at Hudl.
              </Added>
              <Added>
                New confirm and copy{' '}
                <Link href="/components/icons/interactions/design">
                  interaction icons
                </Link>
                .
              </Added>
              <Added>
                A{' '}
                <Link href="/components/icons/media-playback/design">
                  loop one icon
                </Link>{' '}
                to finish up the loop set.
              </Added>
              <Fixed>
                The ability to scroll when the{' '}
                <Link href="/components/action-list/code">action list</Link> is
                open.
              </Fixed>
              <Fixed>
                The weight for the entire interaction icon set to make them nice
                and slim.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="0.54.0"
              versionUrl="https://github.com/hudl/jarvis/releases/tag/v0.54.0"
            />
            <div className={styles.changesList}>
              <Added>All those icons we spoke about earlier.</Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                The documentation for the video player and these release notes.
              </Added>
              <Fixed>A few errant crosslinks.</Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>Support for variants, coming soon.</Added>
              <Improved>
                Auto Layout support on a number of components.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
